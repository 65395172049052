import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

/* eslint-disable no-unused-expressions */
import(/* webpackChunkName: "roboto" */ 'roboto-fontface/css/roboto/roboto-fontface.css')
import(/* webpackChunkName: "mdi" */ '@mdi/font/css/materialdesignicons.css')
/* eslint-enable no-unused-expressions */

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (to.path === '/') {
      return next({ name: 'inbox' })
    }
    if (to.name === 'login') {
      return next({ name: 'inbox' })
    }
    return next()
  }
  if (to.name !== 'login') {
    return next({ name: 'login' })
  } else {
    return next()
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
