import Vue from 'vue'
import Vuex from 'vuex'

import message from './message.module'
import page from './page.module'
import users from './users.module'
import auth from './auth.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    message,
    page,
    users,
    auth
  }
})
