import { MessageService } from '@/commons/ApiService'
import { LOAD_MESSAGE, UPDATE_TASK } from './action.type'
import STATUS from './status.enum'

// private
const ADD_MESSAGE = 'addMessage'
const FORCE_LOAD_MESSAGE = 'forceLoadMessage'
const SET_DISPLAYED_MESSAGE = 'setDisplayedMessage'
const SET_STATUS = 'setStatus'
const REMOVE_FROM_CACHE = 'removeFromCache'

const state = {
  status: STATUS.INIT,
  messages: {},
  displayedMessage: null
}

const getters = {

}

const actions = {
  [LOAD_MESSAGE] ({ commit, dispatch }, { id }) {
    const message = state.messages[id]
    if (message) {
      commit(SET_DISPLAYED_MESSAGE, message)
    } else {
      dispatch(FORCE_LOAD_MESSAGE, { id })
    }
  },
  [FORCE_LOAD_MESSAGE] ({ commit }, { id }) {
    commit(SET_STATUS, STATUS.LOADING)
    MessageService.get(id)
      .then(loadedMessage => {
        commit(SET_STATUS, STATUS.LOADED)
        commit(ADD_MESSAGE, loadedMessage)
        commit(SET_DISPLAYED_MESSAGE, loadedMessage)
      })
      .catch(() => {
        commit(SET_STATUS, STATUS.ERROR)
        commit(SET_DISPLAYED_MESSAGE, null)
      })
  },
  [UPDATE_TASK]  ({ commit, state }, { id, taskDetails }) {
    return MessageService.setTaskDetails(id, taskDetails)
      .then(() => {
        commit(REMOVE_FROM_CACHE, id)
      })
  }
}

const mutations = {
  [ADD_MESSAGE] (state, message) {
    state.messages[message.id] = message
  },
  [REMOVE_FROM_CACHE] (state, id) {
    delete state.messages[id]
  },
  [SET_DISPLAYED_MESSAGE]  (state, message) {
    state.displayedMessage = message
  },
  [SET_STATUS]  (state, status) {
    state.status = status
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
