import { UserService } from '@/commons/ApiService'
import { SET_USERS_TREE } from './mutation.type'
import { FETCH_USERS_TREE } from './action.type'

const state = {
  usersTree: []
}

const getters = {
  usersTree (state) {
    return state.usersTree
  }
}

const actions = {
  [FETCH_USERS_TREE] ({ commit }) {
    UserService.getUserTree()
      .then((json) => {
        commit(SET_USERS_TREE, json)
      })
  }
}

const mutations = {
  [SET_USERS_TREE] (state, usersTree) {
    state.usersTree = usersTree
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
