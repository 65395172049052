// TODO: Переработать загрузку конфига, чтобы избавиться от синхроннго запрос
function synchronousRequest (url) {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, false)
  xhr.send(null)
  if (xhr.status === 200) {
    return JSON.parse(xhr.responseText)
  } else {
    return {}
  }
}
const config = synchronousRequest('/config.json')

if (!config.SERVER_URL) {
  config.SERVER_URL = process.env.VUE_APP_SERVER_URL
}
if (!config.SERVER_URL) {
  config.CLIENT_ID = process.env.VUE_APP_CLIENT_ID
}
if (!config.SERVER_URL) {
  config.CLIENT_SECRET = process.env.VUE_APP_CLIENT_SECRET
}

export const SERVER_URL = config.SERVER_URL
export const API_URL = `${SERVER_URL}/api/v1`
export const CLIENT_ID = config.CLIENT_ID
export const CLIENT_SECRET = config.CLIENT_SECRET
