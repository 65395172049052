import { SERVER_URL, API_URL, CLIENT_ID, CLIENT_SECRET } from './config'
import base64 from 'base-64'
import store from '../store/index'
import { REFRESH_LOGIN } from '../store/action.type'
import { isAfter } from 'date-fns'

export const CONFIG_ERROR = 'configError'
export const AUTH_ERROR = 'authError'
export const SERVER_ERROR = 'serverError'

export const AuthService = {
  login (username, password) {
    const body = {
      grant_type: 'password',
      username,
      password
    }
    return this.auth(body)
  },
  refresh () {
    const token = store.getters.refreshToken
    const body = {
      grant_type: 'refresh_token',
      refresh_token: token
    }
    return this.auth(body)
  },
  auth (data) {
    const headers = {
      Authorization: 'Basic ' + base64.encode(CLIENT_ID + ':' + CLIENT_SECRET)
    }
    const body = new URLSearchParams()
    for (const prop in data) {
      body.set(prop, data[prop])
    }
    return fetch(SERVER_URL + '/oauth/token', { method: 'POST', body, headers })
      .then(response => {
        if (response.status === 401) {
          throw Error(CONFIG_ERROR)
        }
        if (response.status === 400) {
          throw Error(AUTH_ERROR)
        }
        if (!response.ok) {
          throw Error(SERVER_ERROR)
        }
        return response.json()
      })
  }
}

const ApiService = {
  getWithoutRefresh (resource) {
    const expiresDate = store.getters.expiresIn
    if (isAfter(new Date(), expiresDate)) {
      return new Promise((resolve, reject) => reject(new Error(AUTH_ERROR)))
    }
    const token = store.getters.accessToken
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    return fetch(API_URL + resource, { headers })
      .then(response => {
        if (response.status === 401) {
          throw Error(AUTH_ERROR)
        }
        if (!response.ok) {
          throw Error(SERVER_ERROR)
        }
        return response.json()
      })
      .then(json => {
        if (json.error === 'invalid_token') {
          throw Error(AUTH_ERROR)
        }
        return json
      })
  },
  get (resource) {
    return this.getWithoutRefresh(resource)
      .catch(error => {
        if (error.message === AUTH_ERROR) {
          return store.dispatch(REFRESH_LOGIN)
            .then(() => this.getWithoutRefresh(resource))
        } else {
          throw error
        }
      })
  },
  postWithoutRefresh (resource, body) {
    const expiresDate = store.getters.expiresIn
    if (isAfter(new Date(), expiresDate)) {
      return new Promise((resolve, reject) => reject(new Error(AUTH_ERROR)))
    }
    const token = store.getters.accessToken
    const headers = {
      Authorization: `Bearer ${token}`
    }

    return fetch(API_URL + resource, { method: 'POST', body, headers })
      .then(response => {
        if (response.status === 401) {
          throw Error(AUTH_ERROR)
        }
        if (!response.ok) {
          throw Error(SERVER_ERROR)
        }
        return response.json()
      }).then(json => {
        if (json.error === 'invalid_token') {
          throw Error(AUTH_ERROR)
        }
        return json
      })
  },
  post (resource, data) {
    return this.postWithoutRefresh(resource, data)
      .catch(error => {
        if (error.message === AUTH_ERROR) {
          return store.dispatch(REFRESH_LOGIN)
            .then(() => this.postWithoutRefresh(resource, data))
        } else {
          throw error
        }
      })
  },
  deleteWithoutRefresh (resource) {
    const expiresDate = store.getters.expiresIn
    if (isAfter(new Date(), expiresDate)) {
      return new Promise((resolve, reject) => reject(new Error(AUTH_ERROR)))
    }
    const token = store.getters.accessToken
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    return fetch(API_URL + resource, { method: 'DELETE', headers })
      .then(response => {
        if (response.status === 401) {
          throw Error(AUTH_ERROR)
        }
        if (!response.ok) {
          throw Error(SERVER_ERROR)
        }
        return response.json()
      })
      .then(json => {
        if (json.error === 'invalid_token') {
          throw Error(AUTH_ERROR)
        }
        return json
      })
  },
  delete (resource) {
    return this.deleteWithoutRefresh(resource)
      .catch(error => {
        if (error.message === AUTH_ERROR) {
          return store.dispatch(REFRESH_LOGIN)
            .then(() => this.deleteWithoutRefresh(resource))
        } else {
          throw error
        }
      })
  },
  putWithoutRefresh (resource, body) {
    const expiresDate = store.getters.expiresIn
    if (isAfter(new Date(), expiresDate)) {
      return new Promise((resolve, reject) => reject(new Error(AUTH_ERROR)))
    }
    const token = store.getters.accessToken
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }

    return fetch(API_URL + resource, { method: 'PUT', body: JSON.stringify(body), headers })
      .then(response => {
        if (response.status === 401) {
          throw Error(AUTH_ERROR)
        }
        if (!response.ok) {
          throw Error(SERVER_ERROR)
        }
        return response.json()
      }).then(json => {
        if (json.error === 'invalid_token') {
          throw Error(AUTH_ERROR)
        }
        return json
      })
  },
  put (resource, data) {
    return this.putWithoutRefresh(resource, data)
      .catch(error => {
        if (error.message === AUTH_ERROR) {
          return store.dispatch(REFRESH_LOGIN)
            .then(() => this.putWithoutRefresh(resource, data))
        } else {
          throw error
        }
      })
  }
}
export default ApiService

export const InboxService = {
  getPreviewPage (page, tasks, filter) {
    return ApiService.get(`/inbox?page=${page}&tasks=${tasks}&filter=${filter}`)
  },
  getMessage (id) {
    return ApiService.get(`/message/${id}`)
  }
}

export const OutboxService = {
  getPreviewPage (page, tasks, filter) {
    return ApiService.get(`/outbox?page=${page}&tasks=${tasks}&filter=${filter}`)
  },
  getMessage (id) {
    return ApiService.get(`/message/${id}`)
  }
}

export const FileService = {
  getDownloadUrl (fileId) {
    return ApiService.get(`/file/${fileId}`)
  }
}

export const UserService = {
  getUserTree () {
    return ApiService.get('/user/tree')
  }
}

export const MessageService = {
  get (id) {
    return ApiService.get(`/message/${id}`)
  },
  delete (messageId, outbox) {
    return ApiService.delete(`/message/${messageId}${outbox ? '?outbox=true' : ''}`)
  },
  // Возвращает родителей сообщения
  getParentMessages (messageId) {
    return ApiService.get(`/message/${messageId}/parents`)
  },
  setTaskDetails (messageId, taskDetails) {
    return ApiService.put(`/message/${messageId}/task`, taskDetails)
  }
}

export const SendMessageService = {
  sendMessage (message) {
    const body = new FormData(message)
    return ApiService.post('/message', body)
  }
}

export const ResourceService = {
  getResources () {
    return ApiService.get('/resource')
  }
}
