export const FETCH_INBOX_PAGE = 'fetchinboxPage'
export const FETCH_OUTBOX_PAGE = 'fetchOutboxPage'
export const FETCH_INBOX_MESSAGE = 'fetchInboxMessage'
export const FETCH_OUTBOX_MESSAGE = 'fetchOutboxMessage'
export const FETCH_PARENT_MESSAGES = 'fetchParentMessages'
export const FETCH_USERS_TREE = 'fetchUsersTree'
export const FETCH_SETTINGS = 'fetchSettings'

export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REFRESH_LOGIN = 'refreshLogin'

export const UPDATE_TASK = 'updateTask'
// ---
export const CHECK_AUTH = 'checkAuth'

export const LOAD_INBOX = 'loadInbox'
export const LOAD_OUTBOX = 'loadOutbox'
export const LOAD_MESSAGE = 'loadMessage'
