<template>
  <v-app id="app">
    <v-navigation-drawer
      :expand-on-hover="!isMobile && drawer"
      :mini-variant="!isMobile && drawer"
      :permanent="!isMobile"
      v-if="isAuthenticated"
      v-model="drawer"
      app
      clipped
    >
      <v-list>
        <v-list-item to="/new" :class="!isMobile && drawer && 'px-2'">
          <v-list-item-avatar color="secondary">
            <v-icon color="white">mdi-pencil</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Новое сообщение</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="link in links"
          :key="link.label"
          :to="link.to"
          :disabled="link.disabled"
        >
          <v-list-item-icon>
            <v-icon :disabled="link.disabled">{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-html="link.label" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item
          v-for="link in externalLinks"
          :key="link.label"
          :href="link.href"
          :disabled="link.disabled"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-html="link.label" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app clipped-left dense flat>
      <v-app-bar-nav-icon v-if="isAuthenticated" @click.stop="drawer = !drawer" />
      <v-toolbar-title v-html="$route.meta.title" />
      <v-spacer />
      <template v-if="isAuthenticated">
        <v-btn text @click.prevent="logout" class="d-none d-sm-block">
          <v-icon left>mdi-logout</v-icon><span>Выйти</span>
        </v-btn>
        <v-btn icon @click.prevent="logout" class="d-sm-none">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-main :style="floatButtonPadding">
      <transition>
        <router-view />
      </transition>
    </v-main>

    <v-btn v-if="isMobile && isAuthenticated" to="/new" color="pink" fixed dark bottom right fab>
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
  </v-app>
</template>
<script>
import { LOGOUT } from '@/store/action.type'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      links: [
        {
          label: 'Входящие',
          to: '/inbox',
          icon: 'mdi-email'
        },
        {
          label: 'Исходящие',
          to: '/outbox',
          icon: 'mdi-send'
        },
        {
          label: 'Настройки',
          to: '/settings',
          icon: 'mdi-settings',
          disabled: true
        }
      ],
      externalLinks: [
        {
          label: 'Портал',
          href: 'https://ovkhub.ru/',
          icon: 'mdi-domain'
        },
        {
          label: 'Предложения',
          href: 'https://asvio.reformal.ru/',
          icon: 'mdi-lightbulb'
        },
        {
          label: 'Новости разработки',
          href: 'https://asvio.pragmatic-studio.ru/dev-news/',
          icon: 'mdi-newspaper'
        }
      ],
      drawer: null
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    isMobile () {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
    },
    floatButtonPadding () {
      return {
        'padding-bottom': this.isMobile ? '68px !important' : ''
      }
    }
  },
  methods: {
    logout () {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  },
  watch: {
    isAuthenticated: {
      handler (val, oldVal) {
        if (!val && oldVal) {
          this.$router.push({ name: 'login' })
        }
      }
    }
  }
}
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
}
</style>
