import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
    meta: {
      title: 'Входящие'
    },
    props: (route) => ({
      type: 'inbox',
      p: Number.parseInt(route.query.p, 10) || 0,
      tasks: typeof route.query.tasks === 'boolean' ? route.query.tasks : route.query.tasks === 'true',
      filter: typeof route.query.filter === 'string' ? route.query.filter : ''
    })
  },
  {
    path: '/outbox',
    name: 'outbox',
    meta: {
      title: 'Исходящие'
    },
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
    props: (route) => ({
      type: 'outbox',
      p: Number.parseInt(route.query.p, 10) || 0,
      tasks: typeof route.query.tasks === 'boolean' ? route.query.tasks : route.query.tasks === 'true',
      filter: typeof route.query.filter === 'string' ? route.query.filter : ''
    })
  },
  {
    path: '/:type/:id',
    name: 'message',
    meta: {
      title: 'Сообщение'
    },
    component: () => import(/* webpackChunkName: "message" */ '../views/Message.vue'),
    props: (route) => ({
      type: route.params.type,
      id: Number.parseInt(route.params.id, 10)
    })
  },
  {
    path: '/new',
    name: 'new',
    meta: {
      title: 'Новое сообщение'
    },
    props: true,
    component: () => import(/* webpackChunkName: "new" */ '../views/New.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      title: 'Настройки'
    },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Система информационного обмена'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
