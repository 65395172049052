import { AuthService, AUTH_ERROR, CONFIG_ERROR } from '@/commons/ApiService'
import { SET_TOKEN, CLEAR_TOKEN } from './mutation.type'
import { LOGIN, LOGOUT, REFRESH_LOGIN } from './action.type'
import { add } from 'date-fns'

const state = {
  accessToken: localStorage.accessToken || null,
  refreshToken: localStorage.refreshToken || null,
  expiresIn: localStorage.expiresIn || null
}

const getters = {
  accessToken: (state) => state.accessToken === 'null' ? null : state.accessToken,
  refreshToken: (state) => state.refreshToken === 'null' ? null : state.refreshToken,
  expiresIn: state => new Date(state.expiresIn),
  isAuthenticated: (state) => {
    if (state.refreshToken === 'null') {
      return false
    } else {
      return !!state.refreshToken
    }
  }
}

const actions = {
  [LOGIN] ({ commit }, { login, password }) {
    return AuthService.login(login, password)
      .then(json => {
        commit(SET_TOKEN, json)
        return json
      })
      .catch((error) => {
        commit(CLEAR_TOKEN)
        if (error.message === AUTH_ERROR) {
          throw Error('Неправильный логин или пароль')
        }
        if (error.message === CONFIG_ERROR) {
          throw Error('Ошибка конфигурации приложения')
        }
        throw Error('Ошибка обращения к серверу')
      })
  },
  [REFRESH_LOGIN] ({ commit, state }) {
    if (state.refreshToken === 'null') {
      return new Promise(() => {
        throw Error('нет токена')
      })
    }
    return AuthService.refresh(state.refreshToken)
      .then(json => {
        commit(SET_TOKEN, json)
      })
      .catch(() => {
        commit(CLEAR_TOKEN)
      })
  },
  [LOGOUT] ({ commit }) {
    return new Promise((resolve) => {
      commit(CLEAR_TOKEN)
      resolve()
    })
  }
}

const mutations = {
  [SET_TOKEN] (state, json) {
    state.expiresIn = add(new Date(), { seconds: json.expires_in - 5 })
    state.accessToken = json.access_token
    state.refreshToken = json.refresh_token

    localStorage.expiresIn = state.expiresIn
    localStorage.accessToken = state.accessToken
    localStorage.refreshToken = state.refreshToken
  },
  [CLEAR_TOKEN] (state) {
    state.expiresIn = null
    state.accessToken = null
    state.refreshToken = null

    localStorage.expiresIn = null
    localStorage.accessToken = null
    localStorage.refreshToken = null
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
