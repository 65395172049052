import { InboxService, OutboxService } from '@/commons/ApiService'
import STATUS from './status.enum'
import { LOAD_INBOX, LOAD_OUTBOX } from './action.type'

const SET_STATUS = 'setStatus'
const SET_PAGE = 'setPage'

const state = {
  status: STATUS.INIT,
  page: null
}

const getters = {
}

const actions = {
  [LOAD_INBOX] ({ commit }, { page, tasks, filter }) {
    commit(SET_STATUS, STATUS.LOADING)
    InboxService.getPreviewPage(page, tasks, filter)
      .then(loadedPage => {
        commit(SET_STATUS, STATUS.LOADED)
        commit(SET_PAGE, loadedPage)
      })
      .catch(() => {
        commit(SET_STATUS, STATUS.ERROR)
      })
  },
  [LOAD_OUTBOX] ({ commit }, { page, tasks, filter }) {
    commit(SET_STATUS, STATUS.LOADING)
    OutboxService.getPreviewPage(page, tasks, filter)
      .then(loadedPage => {
        commit(SET_STATUS, STATUS.LOADED)
        commit(SET_PAGE, loadedPage)
      })
      .catch(() => {
        commit(SET_STATUS, STATUS.ERROR)
        commit(SET_PAGE, null)
      })
  }
}

const mutations = {
  [SET_STATUS] (state, status) {
    state.status = status
  },
  [SET_PAGE] (state, page) {
    state.page = page
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
